import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import "react-datepicker/dist/react-datepicker.css";
import { ZreportFilterProps } from "./ZReportFilters.type";
import useDateFormatter from "../../hooks/useDateFormatter";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";

const ZreportFilter: React.FC<ZreportFilterProps> = ({
  sendMenuIdToParent,
  sendDateToParent
}) => {
  const { t } = useTranslation();
  // const { formatDate } = useDateFormatter();
  const UserMenus = localStorage.getItem("UserMenus");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [menuId, setMenuId] = useState();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const token = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState<boolean>();
  const { formatDateSP } = useDateFormatter();
  const [fromDate, setFromDate] = useState<any>();
  
      
      const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
      
      const options = UserMenusParsed.map((location: any) => {
        return { value: location.IdMenu, label: location.MenuName };
      });
      const [selectedLocation, setSelectedLocation] = useState(options[0].value);

  useEffect(() => {
    if (location.pathname === "/reports/zreport" && menuId) {
      const getData = async () => {
        const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const requestBody = {
          IdMenu: menuId,
          "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
          "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
        };

        try {
          const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: requestBody,
          });
          const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
          setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getData();
    }
  }, [menuId, location.pathname, currentMonth]);

  useEffect(() => {
    if (allowedDatesFromPicker.length > 0) {
      setFromDate(formatDateSP(allowedDatesFromPicker[0]));
    }
  }, [allowedDatesFromPicker]);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };

  useEffect(() => {
    if (fromDate) {
      sendDateToParent(fromDate);
    }
    sendMenuIdToParent(selectedLocation);
    setMenuId(selectedLocation);
  }, [selectedLocation, fromDate]);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);


  const { disabledDate } = useDateDisabling({ allowedDatesFromPicker });

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;
  
    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    selectedLocation, 
    token, 
    locationPathname: location.pathname 
  });


  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-28 flex flex-col md:flex-row items-center justify-center md:mb-8">
    
    <div className="w-full">
            <Heading
              align="left"
              children={t("zreport.title")}
              classes="pt-16 md:pt-0 min-w-full md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("zreport.subTitle")}
              classes="font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>
    
      <div className="w-full md:hidden">
        <Select
          classes="flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
        />
        </div>

          <div className="flex flex-col h-fit w-full md:w-8/12 mt-4">
            <p className="text-base mb-1">{t("zreport.date")}</p>
            <fieldset>
              {isMobile && fromDate ? 
              <CustomDatePicker
              dateValue={fromDate}
              onDateChange={setFromDate}
              disabledDate={disabledDate}
              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
              placeholder={t("datePicker.placeholder")}
              allowedDates={allowedDatesFromPicker}
            />
               : fromDate && 
               <CustomDatePicker
               dateValue={fromDate}
               onDateChange={setFromDate}
               disabledDate={disabledDate}
               handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
               placeholder={t("datePicker.placeholder")}
               allowedDates={allowedDatesFromPicker}

             />
            }
             
            </fieldset>
          </div>
    </Wrapper>
  );
};

export default ZreportFilter;

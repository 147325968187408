import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useRef } from 'react';

interface CustomDatePickerProps {
  dateValue: string | null;
  onDateChange: (date: string) => void;
  // onTimeChange: (time: string) => void;
  disabledDate?: (current: any) => boolean;
  handleMonthChange?: (date: Date) => void;
  placeholder: string;
  className?: string;
  isToDatePicker?: boolean; // to differentiate between fromDate and toDate
  allowedDates?: any[];
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  dateValue,
  onDateChange,
  // onTimeChange,
  disabledDate,
  handleMonthChange,
  placeholder,
  className,
  isToDatePicker = false,
  allowedDates
}) => {
  const ref = useRef(null);

  // Parse the default date from props and set default time (12 AM or 11 AM)
  // dayjs(fromDate).hour(0).minute(0)
  const defaultDate = dateValue ? dayjs(`${dateValue} ${isToDatePicker ? '11 AM' : '12 AM'}`, 'DD/MM/YYYY hh A') : null;

  const isAllowedDate = (date: dayjs.Dayjs) => {
    return allowedDates?.some((allowedDate) =>
      dayjs(allowedDate).isSame(date, 'day')
    );
  };
  const isSelectedDate = (current: dayjs.Dayjs) => {
    return dateValue && dayjs(dateValue).isSame(current, 'day');
  };
  return (
    <div className="flex flex-col h-14">
    <fieldset>
    <DatePicker
      ref={ref}
      format="DD/MM/YYYY"
      datatype="dd/mm/yyyy"
      defaultValue={defaultDate}
      onChange={(date, dateString) => {
          // Ensure dateString is a string
          if (typeof dateString === 'string') {
            const [datePart, timePart] = dateString.split(' '); // Split the date and time
            if(timePart){
              
              const hour = parseInt(timePart.split(' ')[0], 10); // Get the hour from the time part
              const isPM = timePart.includes('PM');
              let hourIn24 = hour;
              
              if (isPM && hourIn24 < 12) {
                hourIn24 += 12; // Convert PM to 24-hour format
              } else if (!isPM && hourIn24 === 12) {
                hourIn24 = 0; // Convert 12 AM to 0
              }
              
              // const formattedTime = hourIn24.toString().padStart(2, '0');
              // onTimeChange(formattedTime)
            }
            onDateChange(datePart)
          } else {
            console.error('dateString is not a string:', dateString);
          }
        }} // Pass the function that updates state
      showTime={false}
      inputReadOnly 
      // disabledDate={disabledDate}
      showNow={false}
      onPanelChange={(date: any, mode: string) => {
        if (mode === 'date' && handleMonthChange) {
          handleMonthChange(date.toDate());
        }
      }}
      placeholder={placeholder}
      className={className || 'bg-custom-bgBlue text-white py-2 border-none w-full text-xxs md:text-xs h-8 md:h-10'}
      cellRender={(current: any) => {
        const isAllowed = isAllowedDate(current);
        const isSelected = isSelectedDate(current);

        return (
           <div
                className={`ant-picker-cell-inner ${
                  isAllowed ? 'text-[#7DD181]' : ''
                } ${isSelected ? 'bg-custom-bgBlue text-white rounded-full' : ''}`}
              >
                {current.date()}
              </div>
        );
      }}

    />
    </fieldset>
  </div>
  );
};
export default CustomDatePicker;

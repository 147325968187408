import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import { PercentageReportFiltersProps } from "./PercentageReportFilter.type";
import i18n from "../../i18n";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import CustomTimePicker from "../common/customTimePicker/CustomTimePicker";
import useTimeConverter from "../../hooks/useTimeConverter";
import useDateFormatter from "../../hooks/useDateFormatter";

const PercentageReportFilters: React.FC<PercentageReportFiltersProps> = ({sendFiltersToParent}) => {
  const { t } = useTranslation();
  const { formatDateSP } = useDateFormatter();
  const now = new Date();
  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth(), 1)));
  const [toDate, setToDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth() + 1, 0)));
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("23:00");
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();
  const menuId = selectedLocation;
  const [exportOptions, setExportOptions] = useState<any>([]);
  const [selectedExportType, setSelectedExportType] = useState<any>("0");
  const [allFilters, setAllFilters] = useState<any>({
    "IdMenu": "",
    "TargetDate": "",
    "TargetDateTo": "",
    "Language": "",
    "HourFilterFrom": "",
    "HourFilterTo": "",
    "ExportType": "",
    "MainCategoryIds": "",
    "SubCategoryIds": "",
    "ProductIds": "",
    "TaxIds": ""
});
const {convertTimeToHour} = useTimeConverter()



  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };

  useEffect(() => {
    sendFiltersToParent(allFilters);
  }, [allFilters, sendFiltersToParent, ]);

  useEffect(() => {
    setAllFilters({
      "IdMenu": menuId,
      TargetDate: fromDate,
      TargetDateTo: toDate,
      "Language": i18n.resolvedLanguage,
      "HourFilterFrom": convertTimeToHour(fromTime),
      "HourFilterTo": convertTimeToHour(toTime),
      "ExportType": selectedExportType.toString(),
      "MainCategoryIds": "",
      "SubCategoryIds": "",
      "ProductIds": "",
      "TaxIds": ""
  })
  }, [menuId, i18n.resolvedLanguage, selectedExportType, fromDate, toDate, fromTime, toTime])
  useEffect(() => {
    if (menuId) {
      const getData = async () => {
        
        // setIsLoadingLiveData(true);
        const path = `${API_ROUTES.getFiltersData}?idClientMenu=${menuId}`
        
        try {
          const apiResponse = await fetchData(path, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
          });

          setExportOptions(apiResponse.data.ExportTypes)
        
          
        } catch (error) {
        } finally {
          // setIsLoadingLiveData(false);
        }
      };
      
      getData();
    }
  }, [menuId, location.pathname]);



  useEffect(() => {
    if (
       location.pathname === "/reports/percentageReport"
    ) {
      if (selectedLocation) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: selectedLocation,
            "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
            setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
            setAllowedDatesToPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [selectedLocation, location.pathname]);

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    selectedLocation, 
    token, 
    locationPathname: location.pathname 
  });

  const { disabledDate, disabledToDate } = useDateDisabling({ allowedDatesFromPicker, allowedDatesToPicker });

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-24 mt-1 flex flex-col items-center justify-center gap-4 ">
      <div className="w-full flex flex-col md:flex-row items-center justify-center">


        <div className="w-full flex flex-col">

        <div className="w-full">
          <Heading
            align="left"
            children={t("percentageReport.title")}
            classes="pt-16 md:pt-0 mt-1 min-w-full md:text-4xl"
            color=""
            size="h1"
          />
          <Text
            align=""
            children={t("percentageReport.subTitle")}
            classes="font-thin"
            color="text-custom-textGraySec"
            size=""
          />
        </div>

        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
          />
          </div>
        <div className="w-full md:w-8/12 mt-4 flex gap-2">

        
        <div className="text-left w-1/2">
          <p className="mb-2">{t("percentageReport.fromToDate")}</p>
              {fromDate && toDate &&
              <span className="grid grid-cols-2 gap-2">
                              <CustomDatePicker
                              dateValue={fromDate}
                              onDateChange={setFromDate}
                              disabledDate={disabledDate}
                              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
                              placeholder={t("datePicker.placeholder")}
                              allowedDates={allowedDatesFromPicker}
                              />
                              <CustomDatePicker
                              dateValue={toDate}
                              onDateChange={setToDate}
                              disabledDate={disabledToDate}
                              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesToPicker)}}
                              placeholder={t("datePicker.placeholder")}
                              isToDatePicker={true}
                              allowedDates={allowedDatesToPicker}
                              />
                            </span>
      }
        
        </div>
        <div className="text-left w-1/2">
          <p className="mb-2">{t("percentageReport.fromToTime")}</p>
          <span className="grid grid-cols-2 gap-2">
              <CustomTimePicker
                selectedTime={fromTime}
                onTimeChange={setFromTime}
                />
              <CustomTimePicker
                selectedTime={toTime}
                onTimeChange={setToTime}
                />
          </span>
        </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PercentageReportFilters;

import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import ZReportRow from "../zReportRow/ZReportRow";
import { useTranslation } from "react-i18next";
// import useDateFormatter from "../../hooks/useDateFormatter";
import { v4 as uuidv4 } from 'uuid';
import { MenuType, PaymentType, Props } from "./PercentageReport.type";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import PercentageReportTable from "../percentageReportTable/PercentageReportTable";
import PeriodReportTable from "../periodReportTable/PeriodReportTable";
import { createColumnHelper, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";
import Dropdown from "../common/dropdown/Dropdown";
import { groupData, transformGroupedDetails } from "../../utilities/percentageReportUtils";

const PercentageReport: React.FC<Props> = ({ filters}) => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [menuName, setMenuName] = useState("");
  const [corrections, setCorecctions] = useState<any>([]);
  const [ownUse, setOwnUse] = useState<any>([]);
  // const { formatDateAndTime, formatDateWithoutTime } = useDateFormatter();
  const {t} = useTranslation();
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [percentagesData, setPercentagesData] = useState<any>([]);
  const [taxParentGroupDetails, setParentGroupDetails] = useState<any>([]);
  const [tips, setTips] = useState<any>([]);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    sales: true,
    vatTotals: true,
    payments: true,
    discounts: true,
    tips: true,
    serviceFee: true
  });
  const [products, setProducts] = useState<any>([])
  const [couverts, setCouverts] = useState<any>([])
  const [outOfTurnoverProducts, setOutOfTurnoverProducts] = useState<any>([])
  const [flattenedProducts, setFlattenedProducts] = useState<any>([]);
  const [vatTotals, setVatTotals] = useState<any>([])
  const [discounts, setDiscounts] = useState<any>([])
  const [serviceFee, setServiceFee] = useState<any>([])
  const [exportType, setExportType] = useState<any>("0");
  const exportTypeOptions = [ 
    {value: '0', label: 'NO EXPORT'},
    {value: '1', label: 'PDF'},
    {value: '2', label: 'CSV'}];

    const updateFiltersWithExportType = (filters: any, exportType: any) => {
      return {
        ...filters,
        ExportType: exportType,  // Update the ExportType field
      };
    };

  const getDataBe = () => {
    if (
      location.pathname === "/reports/percentageReport"
    ) {

      const updatedFilters = updateFiltersWithExportType(filters, exportType);
      if (filters) {
        const getData = async () => {
          const requestBody = updatedFilters;
          
          setIsLoadingLiveData(true);
          
          try {
            const apiResponse = await fetchData(API_ROUTES.getPercentagesReport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });

            setFileUrl(apiResponse.data.DocumentUrl.FileName)
            setPercentagesData(apiResponse.data.PercentagesData)
            setPaymentData(apiResponse.data.paymentTypes)
            setParentGroupDetails(apiResponse.data.alltaxes)
            setVatTotals(apiResponse.data.TaxParentGroupDetails)
            setDiscounts(apiResponse.data.discounts)
            setTips(apiResponse.data.Tips)
            setServiceFee(apiResponse.data.ServiceFee)
          
          } catch (error) {

          } finally {
            setIsLoadingLiveData(false);
          }
        };
        
        getData();
      }
    }
  }

  useEffect(() => {
    if (percentagesData.length > 0) {
      const grouped = percentagesData.reduce((acc: any, product: any) => {
        const { MainCategory, SubCategory } = product;

        if (!acc[MainCategory]) {
          acc[MainCategory] = {};
        }
        if (!acc[MainCategory][SubCategory]) {
          acc[MainCategory][SubCategory] = [];
        }

        acc[MainCategory][SubCategory].push(product);
        return acc;
      }, {});

      const flattened: any = [];
      for (const mainCategory in grouped) {
        for (const subCategory in grouped[mainCategory]) {
          grouped[mainCategory][subCategory].forEach((product: any) => {
            flattened.push({
              ...product,
              MainCategory: mainCategory,
              SubCategory: subCategory,
            });
          });
        }
      }
      setFlattenedProducts(flattened);
    }
  }, [percentagesData]);

useEffect(() => {
  const userMenusString = localStorage.getItem("UserMenus");
  if (userMenusString) {
    const userMenus: MenuType[] = JSON.parse(userMenusString);
    if (userMenus.length > 0) {
      setMenuName(userMenus[0].MenuName);
    }
  }
}, []);

let totalCorrcetionsAmount;
let totalPaymentsAmount;
let tipsAmountTotal;
let serviceFeeAmountTotal
{tips.length > 0 ? (tipsAmountTotal = tips.reduce((sum: AudioNode, item: any) => sum + item.Amount, 0)) : (tipsAmountTotal = 0)}
{serviceFee.length > 0 ? (serviceFeeAmountTotal = serviceFee.reduce((sum: any, item: any) => sum + item.Amount , 0)) : (serviceFeeAmountTotal = 0)}
{corrections.length > 0 ? (totalCorrcetionsAmount = corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0)) : (totalCorrcetionsAmount = 0)}
{paymentData.length > 0 ? (totalPaymentsAmount = paymentData.reduce((sum: any, item: any) => sum + item.TotalOrderGroupWithDiscount, 0)) : (totalPaymentsAmount = 0)}
const [sumsByStatus, setSumsByStatus] = useState<any>([]);

useEffect(() => {
  const sums: { [key: number]: { status: string; quantity: number } } = {};

  ownUse.forEach((order: any) => {
    const { IdStatus, Status, Quantity } = order;
    if (!sums[IdStatus]) {
      sums[IdStatus] = { status: Status, quantity: 0 };
    }
    sums[IdStatus].quantity += Quantity;
  });

  const mappedSums = Object.keys(sums).map((idStatus) => ({
    idStatus: parseInt(idStatus, 10), // Explicitly parse to number
    status: sums[parseInt(idStatus, 10)].status, // Explicitly parse to number
    quantity: sums[parseInt(idStatus, 10)].quantity, // Explicitly parse to number
  }));

  setSumsByStatus(mappedSums);
}, [ownUse]);

function limitToTwoDecimals(number: any) {
  if (number % 1 === 0) {
      return String(number);
  } else {
      return Number(number).toFixed(2);
  }
}

useEffect(() => {
  if (percentagesData.length > 0) {
    const productsArray: any = [];
    const couvertsArray: any = [];
    const outOfTurnoverProductsArray: any = [];
    percentagesData.forEach((product: any) => {
      if (product.ProductType == 0) {
        productsArray.push(product);
      } else if (product.ProductType == 1) {
        couvertsArray.push(product);
      } else if (product.ProductType == 3) {
        outOfTurnoverProductsArray.push(product);
      }
    });

    setProducts(productsArray);
    setCouverts(couvertsArray);
    setOutOfTurnoverProducts(outOfTurnoverProductsArray);
  }
}, [percentagesData])

const toggleSection = (section: string) => {
  setOpenSections(prevOpenSections => ({
    ...prevOpenSections,
    [section]: !prevOpenSections[section]
  }));
};

// const productsTH = [
//   {id: 1, title: t("percentageReport.saleTotals")},
//   {id: 2, title: t("percentageReport.qty")},
//   {id: 3, title: t("percentageReport.amount")},
//   {id: 4, title: t("percentageReport.perc")}
// ];

// const vatTotalsTH = [
//   {id: 1, title: t("percentageReport.taxName")},
//   {id: 2, title: t("percentageReport.turnover")},
//   {id: 3, title: t("percentageReport.vat")},
// ];

// const discountsTH = [
//   {id: 4, title: t("percentageReport.date")},
//   {id: 5, title: t("percentageReport.table")},
//   {id: 6, title: t("percentageReport.time")},
//   {id: 7, title: t("percentageReport.disc")},
//   {id: 8, title: t("percentageReport.amt")},
// ]

const groupedSales = groupData(flattenedProducts);
const groupedCouverts = groupData(couverts);
const groupedOOT = groupData(outOfTurnoverProducts);

const transformedDetails = transformGroupedDetails(groupedSales, 0, t);
const transformedCouverts = transformGroupedDetails(groupedCouverts, 1, t);
const transformedOOT = transformGroupedDetails(groupedOOT, 3, t);


const columnHelper = createColumnHelper<any>()

const vatTotalsTH = [
  columnHelper.accessor('VatName', {
    header: t("percentageReport.taxName")
  }),
  columnHelper.accessor('Ammount', {
    header: t("percentageReport.turnover")
  }),
  columnHelper.accessor('Percentage', {
    header: t("percentageReport.vat")
  }),
]

  const discountsTH = [
    columnHelper.accessor('Datum', {
      header: t("percentageReport.date")
    }),
    columnHelper.accessor('TableShortDescription', {
      header: t("percentageReport.table")
    }),
    columnHelper.accessor('DateTimeDiscount', {
      header: t("percentageReport.time")
    }),
    columnHelper.accessor('DiscountText', {
      header: t("percentageReport.disc")
    }),
    columnHelper.accessor('DiscountAmount', {
      header: t("percentageReport.amt")
    }),
  ]

  const productsTH = [
    columnHelper.accessor('SubCategory', {
      header: t("percentageReport.saleTotals")
    }),
    columnHelper.accessor('Quantity', {
      header: t("percentageReport.qty"),
      cell: (info) => {
        const value = info.getValue();
        return value === 0 ? value : value;
      }
    }),
    columnHelper.accessor('SubTotal', {
      header: t("percentageReport.amount"),
      cell: (info) => {
        const value = info.getValue();
        return value === 0 ? value : typeof value === 'number' ? value.toFixed(2) : value;
      }
    }),
    columnHelper.accessor('Percents', {
      header: t("percentageReport.perc"),
      cell: (info) => {
        const value = info.getValue();
        return value === 0 ? `${value}%` : typeof value === 'number' ? `${value.toFixed(2)}%` : `${value}%`;
      }
      
    })
  ]




const options = {
  data: vatTotals,
columns: vatTotalsTH,
getCoreRowModel: getCoreRowModel(),
getPaginationRowModel: getPaginationRowModel(),
initialState: {
  pagination: {
    pageIndex: 0, //custom initial page index
    pageSize: 10, //custom default page size
  },
},
}

const options2 = {
  data: discounts,
columns: discountsTH,
getCoreRowModel: getCoreRowModel(),
getPaginationRowModel: getPaginationRowModel(),
initialState: {
  pagination: {
    pageIndex: 0, //custom initial page index
    pageSize: 10, //custom default page size
  },
},
}

const options3 = {
  data: transformedDetails,
columns: productsTH,
getCoreRowModel: getCoreRowModel(),
getPaginationRowModel: getPaginationRowModel(),
initialState: {
  pagination: {
    pageIndex: 0, //custom initial page index
    pageSize: 10, //custom default page size
  },
},
}


const handleExportChange = (exportType: any) => {
  setExportType(exportType[0])
}



  return (
    <Wrapper onClick={() => {}} classes={`w-[95%] mx-auto flex flex-col items-center pt-2 md:pt-4 pb-16 min-h-screen`}>
    <div className="md:hidden mb-8 w-full">
        <div className="w-full flex gap-2 mb-4">
            <span className={` ${!fileUrl ? "w-full" : "w-1/2"}`}>
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>
            {fileUrl && (
        <button
        onClick={() => window.open(fileUrl, "_blank")}
        className="w-1/2 md:w-1/4  p-2 rounded-lg text-center flex justify-center border text-custom-bgBlue border-custom-bgBlue"
        >
        {t("soldProducts.download")}
      </button>
    )}
        </div>
            <span className="md:w-1/5">
              <button className="w-full rounded-lg px-4 py-2 bg-custom-bgBlue text-white" onClick={getDataBe}>{t("soldProducts.generate")}</button>
            </span>
      </div>
      <div className="hidden md:flex justify-between mb-12 w-full">
            <span className="w-1/7">
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>

            <div className="grid grid-cols-2 grid-flow-row-dense w-2/5 gap-2">
              {<span className={`w-full ${fileUrl ? "" : "invisible"} `}>
                {fileUrl &&   <button className="w-full border rounded-lg px-4 py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("soldProducts.download")}</button>}
            
            </span>}
  
            <span className="w-full">
              <button className="w-full rounded-lg px-4 py-2 bg-custom-bgBlue text-white" onClick={getDataBe}>{t("periodReport.generate")}</button>
            </span>
            </div>
      </div>


    {products.length > 0 ? (
        <div 
          onClick={() => toggleSection("sales")} 
          className="flex justify-between items-center px-4 rounded-lg border border-gray-200 w-full mb-0 relative my-2 py-4 bg-white shadow-custom"
        >
          {t("percentageReport.sales")} {openSections["sales"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {products.length > 0 && openSections["sales"] ? <PercentageReportTable options={options3}/> : <></>}
      
      {vatTotals.length > 0 ? (
        <div 
          onClick={() => toggleSection("vatTotals")} 
          className="flex justify-between items-center px-4 rounded-lg border border-gray-200 w-full relative mb-0 my-2 py-4 bg-white shadow-custom"
        >
          {t("percentageReport.vatTotals")} {openSections["vatTotals"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {vatTotals.length > 0 && openSections["vatTotals"] ? <PeriodReportTable options={options} tableHeads={vatTotalsTH} vatTotals={vatTotals}/> : <></>}
      
      {paymentData.length > 0 ? (
        <div 
          onClick={() => toggleSection("payments")} 
          className="flex justify-between items-center mb-1 px-4 rounded-lg border border-gray-200 w-full relative my-2 py-4 bg-white shadow-custom"
        >
          {t("percentageReport.payments")} {openSections["payments"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {paymentData.length > 0 && openSections["payments"] ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg">
        {paymentData.map((item: any) => {
          return(
          <ZReportRow key={uuidv4()} title={item.PaymentType} value={limitToTwoDecimals(item.TotalOrderGroupWithDiscount)} color={item.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}/>
        )
      })}
      <ZReportRow key={uuidv4()} title={t("percentageReport.total")} value={totalPaymentsAmount.toFixed(2)} color="bg-custom-bgPurple2" classes="py-2 mt-2"/>
          
            </Wrapper> ) : (<></>)}

      {discounts.length > 0 ? (
        <div 
          onClick={() => toggleSection("discounts")} 
          className="flex justify-between items-center px-4 mb-0 rounded-lg border border-gray-200 w-full relative my-2 py-4 bg-white shadow-custom"
        >
          {t("percentageReport.discounts")} {openSections["discounts"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {discounts.length > 0 && openSections["discounts"] ? (<PeriodReportTable options={options2} tableHeads={discountsTH} discountsPercentageReport={discounts}/>) : (<></>)}

      {tips.length > 0 ? (
                <div 
                  onClick={() => toggleSection("tips")} 
                  className="flex justify-between items-center mb-1 px-4 rounded-lg border border-gray-200 w-full relative my-2 py-4 bg-white shadow-custom"
                >
                  {t("percentageReport.tips")} {openSections["tips"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
                </div>
      ) : (<></>)}
      {tips.length > 0 && openSections["tips"] ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg"> 
      <ZReportRow key={uuidv4()} title={tips.length} value={tipsAmountTotal} />
      </Wrapper>) : (<></>)}

      {serviceFee.length > 0 ? (
              <div 
                onClick={() => toggleSection("serviceFee")} 
                className="flex justify-between items-center px-4 rounded-lg border border-gray-200 w-full relative my-2 py-4 bg-white shadow-custom"
              >
                {t("percentageReport.serviceFee")} {openSections["   serviceFee"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
              </div>
      ) : (<></>)}
      {serviceFee.length > 0 && openSections["serviceFee"] ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg"> 
      <ZReportRow key={uuidv4()} title={serviceFee.length} value={serviceFeeAmountTotal} />
      </Wrapper>) : (<></>)}
   
      <ScrollToTopButton />
    </Wrapper>
  );
};

export default PercentageReport;

import Wrapper from "../common/wrapper/Wrapper";
import React, { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { PeriodProps } from "./PeriodReport.type";
import PeriodReportTable from "../periodReportTable/PeriodReportTable";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import { useTranslation } from "react-i18next";
import ZReportRow from "../zReportRow/ZReportRow";
import { v4 as uuidv4 } from 'uuid';
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import { createColumnHelper, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table'
import Dropdown from "../common/dropdown/Dropdown";

const PeriodReports: React.FC<PeriodProps> = ({menuId, dateFrom, dateTo}) => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const [details, setDetails] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [taxExcludeVat, setTaxExcludeVat] = useState<any>([]);
  const [tax, setTax] = useState<any>([]);
  const [turnover, setTurnover] = useState<any>([]);
  const [payments, setPayments] = useState<any>([]);
  const [onTheHouse, setOnTheHouse] = useState<any>([])
  const [corrections, setCorrections] = useState<any>([])
  const [outOfTurnover, setOutOfTurnover] = useState<any>([])
  const [totalAmount, setTotalAmount] = useState<any>([])
  const [sumsByStatus, setSumsByStatus] = useState<any>([]);
  const [taxParentGroupDetails, setTaxParentGroupDetails] = useState<any>([]);
  const {t} = useTranslation();
  const [openSection, setOpenSection] = useState<string | null>(null);
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const exportTypeOptions = [ 
  {value: '0', label: 'NO EXPORT'},
  {value: '1', label: 'PDF'},
  {value: '2', label: 'CSV'}];
  const [fileUrl, setFileUrl] = useState<null | any>(null);
  const [exportType, setExportType] = useState<any>("0");

  const columnHelper = createColumnHelper<any>()

  const daysTH = [
    columnHelper.accessor('LastModified', {
      header: t("periodReport.date")
    }),
    columnHelper.accessor('AvgPB', {
      header: t("periodReport.avgPB")
    }),
    columnHelper.accessor('AvgPP', {
      header: t("periodReport.avgPP")
    }),
    columnHelper.accessor('TotalOrders', {
      header: t("periodReport.total")
    }),
  ]

  const options = {
    data: details,
  columns: daysTH,
  getCoreRowModel: getCoreRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  initialState: {
    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 10, //custom default page size
    },
  },
}
  

  const fetchReportData = async () => { 
    if (menuId !== "" && dateFrom !== "" && dateTo !== "") {
      const requestBody = {
        Language: i18n.language,
        IdMenu: menuId,
        TargetDate: dateFrom,
        TargetDateTo: dateTo,
        ExportType: exportType
      };
      setIsLoadingLiveData(true);

      try {
        const apiResponse = await fetchData(API_ROUTES.getPeriodReport, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        });

        setDetails(apiResponse.data.details);
        setDiscounts(apiResponse.data.discounts);
        setTaxExcludeVat(apiResponse.data.alltaxes.taxExclVAT);
        setTax(apiResponse.data.alltaxes.taxVAT);
        setTurnover(apiResponse.data.alltaxes.turnover);
        setPayments(apiResponse.data.paymentTypes);
        setOnTheHouse(apiResponse.data.fromHouse);
        setCorrections(apiResponse.data.corrections);
        setOutOfTurnover(apiResponse.data.OutOfTurnover);
        setTotalAmount(apiResponse.data.totalAmount);
        setTaxParentGroupDetails(groupByTaxType(apiResponse.data.TaxParentGroupDetails));
        setFileUrl(apiResponse.data.DocumentUrl && apiResponse.data.DocumentUrl.FileName);
      } catch (error) {
        setDetails([]);
        setDiscounts([]);
        setTaxExcludeVat([]);
        setTax([]);
        setTurnover([]);
        setPayments([]);
        setOnTheHouse([]);
        setCorrections([]);
        setOutOfTurnover([]);
        setTotalAmount([]);
        setTaxParentGroupDetails([]);
        console.error(error)
      } finally {
        setIsLoadingLiveData(false);
      }
    }
  };


  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  function limitToTwoDecimals(number: any) {
    if (number % 1 === 0) {
        return String(number);
    } else {
        return Number(number).toFixed(2);
    }
  }

  const getSum = (items: any) => {
    return items.reduce((total: any, item: any) => total + item.Ammount, 0);
  }
  const groupByTaxType = (details: any) => {
    return details.reduce((groups: any, item: any) => {
      if (!groups[item.TaxType]) {
        groups[item.TaxType] = [];
      }
      groups[item.TaxType].push(item);
      return groups;
    }, []);
  };

  useEffect(() => {
    const sums: { [key: string]: { status: string; quantity: number } } = {};
  
    onTheHouse.forEach((order: any) => {
      const { Status, Quantity } = order;
  
      // Ensure that both Status and Quantity exist
      if (Status && Quantity !== undefined) {
        if (!sums[Status]) {
          sums[Status] = { status: Status, quantity: 0 };
        }
        sums[Status].quantity += Quantity;
      } else {
        console.warn('Order is missing necessary properties:', order);
      }
    });
  
    const mappedSums = Object.keys(sums).map((status) => ({
      status,
      quantity: sums[status].quantity,
    }));
  
    setSumsByStatus(mappedSums);
  }, [onTheHouse]);

  const handleExportChange = (exportType: any) => {
    setExportType(exportType[0])
  }

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto pt-2 md:pt-4 pb-16">
      

      <div className="md:hidden mb-8">
        <div className="w-full flex gap-2 mb-4">
            <span className={`${!fileUrl ? "w-full" : "w-1/2"}`}>
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>
            {fileUrl &&  <span className="w-1/2 md:w-1/5">
              <button className="w-full border rounded-lg px-4 py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.download")}</button>
            </span>}
           
        </div>
            <span className="md:w-1/5">
              <button className="w-full rounded-lg px-4 py-2 bg-custom-bgBlue text-white" onClick={fetchReportData}>{t("periodReport.generate")}</button>
            </span>
      </div>
      <div className="hidden md:flex justify-between mb-12">
            <span className="w-1/7">
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>

            <div className="grid grid-cols-2 grid-flow-row-dense w-2/5 gap-2">
              { <span className={`w-full ${fileUrl ? "" : "invisible"}`}>
              <button className="w-full border rounded-lg px-4 py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.download")}</button>
            </span>}
            <span className="w-full">
              <button className="w-full rounded-lg px-4 py-2 bg-custom-bgBlue text-white" onClick={fetchReportData}>{t("periodReport.generate")}</button>
            </span>
  
            </div>
      </div>

      {details.length > 0 ? (<><div onClick={() => toggleSection("days")} className={`${openSection === "days" ? "mb-4" : ""} flex justify-between items-center px-4 rounded-lg border border-gray-200 w-full py-4 bg-white shadow-custom3`}>{t("periodReport.days")} {openSection !== "days" ? (<ArrowUpIcon/>) : (<ArrowDownIcon />)} </div>
            {openSection !== "days" ? <div className="mb-4">{details.length > 0 && <PeriodReportTable options={options} details={details} tableHeads={daysTH}/>}</div> : <></>}
            
            <div className="md:hidden">
            {isTaxParentGroup && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.parentTaxGroup")}
            </p>}
            {/* parent group */}
            {isTaxParentGroup ? <Wrapper classes={`${taxParentGroupDetails.length > 0 ? " rounded-lg" : "mt-4"}`} onClick={() => {}}>
            
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-custom3">
              <ZReportRow title={t("periodReport.excludedVAT")} value={limitToTwoDecimals(getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="" fontSize="text-base"/>
              {taxExcludeVat.length > 0 ? taxExcludeVat.map((item: any) => {return(<ZReportRow key={uuidv4()}  title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />)}) : <></>}

              <ZReportRow title={t("periodReport.tax")} value={limitToTwoDecimals(getSum(tax))} color="bg-custom-bgPink"  classes=" mt-4" fontSize="text-base"/>
              {tax.length > 0 ? tax.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}

              <ZReportRow title={t("periodReport.turnover")} value={limitToTwoDecimals(getSum(turnover))} color="bg-custom-bgPink"  classes=" mt-4" fontSize="text-base"/>
              {turnover.length > 0 ? turnover.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}

              </Wrapper>
            </Wrapper> : <></>}
   
            

            {taxParentGroupDetails.length > 0 ? ( <div className="py-2 mb-3 ">
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.excludedVAT")}
            </p>

            {taxParentGroupDetails.length > 0 ? ( 
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {taxParentGroupDetails.length > 0 && taxParentGroupDetails[1].map((item: any) => {
                return (
                  <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
                );
              })}
              <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(taxExcludeVat && getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
            </Wrapper>) : (<></>)}

            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.tax")}
            </p>

            {taxParentGroupDetails.length > 0 ? (  
                    
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {taxParentGroupDetails.length > 0 && taxParentGroupDetails[2].map((item: any) => {
                return (
                  <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
                );
              })}
              <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(tax && getSum(tax))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
            </Wrapper>) : (<></>)}

            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.turnover")}
            </p>
            {taxParentGroupDetails.length > 0 ? (        
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-1 shadow-custom3">
              {taxParentGroupDetails.length > 0 && taxParentGroupDetails[3].map((item: any) => {
                return (
                  <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
                );
              })}
              <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(turnover && getSum(turnover))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
            </Wrapper>) : (<></>)}
              </div>) : (<></>)}
              {payments.length > 0 &&  <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.payment")}
            </p>}
             
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {payments.length > 0 ? payments.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.PaymentType} value={limitToTwoDecimals(item.TotalOrderGroupWithDiscount)} color={`${item.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(payments.reduce((total: any, item: any) => total + item.TotalOrderGroupWithDiscount, 0))} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-base"/>
              </Wrapper>
   
            {details.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.dailyTotals")}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}} >
              <ZReportRow title={t("periodReport.totalDays")} value={limitToTwoDecimals(details?.length)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.turnover")} value={limitToTwoDecimals(totalAmount?.TotalOrder)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.discounts")} value={limitToTwoDecimals(totalAmount?.Discount)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(totalAmount?.TotalWithDiscount)} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md" fontSize="text-base"/>
            </Wrapper> 
              {sumsByStatus.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
            {sumsByStatus.map((item: any) => {
            return(
              <ZReportRow key={uuidv4()} title={item.status} value={limitToTwoDecimals(item.quantity)}/>
            )
          })}
            </Wrapper>
          {corrections &&   <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}}>
            <ZReportRow title={`${t("periodReport.corrections")} (${corrections?.length}x)`} value={limitToTwoDecimals(corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0))}/>
            <ZReportRow title={`${t("periodReport.discounts")} (${discounts?.length}x)`} value={limitToTwoDecimals(discounts.reduce((sum: any, item: any) => sum + item.DiscountAmount, 0))}/>
            {outOfTurnover.detailsOOT.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.detailsOOT.length}x)`} value={outOfTurnover && outOfTurnover.totalAmountOOT.TotalWithDiscount} color="bg-custom-bgOrange"/>) : (<></>)}
            </Wrapper>
            </div>

            <div className="hidden md:flex w-full gap-4">
              <div className="w-1/2">
              {payments.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.payment")}
            </p>}
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {payments.length > 0 ? payments.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.PaymentType} value={limitToTwoDecimals(item.TotalOrderGroupWithDiscount)} color={`${item.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(payments.reduce((total: any, item: any) => total + item.TotalOrderGroupWithDiscount, 0))} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-base"/>
              </Wrapper>

            {details.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.dailyTotals")}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}} >
              <ZReportRow title={t("periodReport.totalDays")} value={limitToTwoDecimals(details?.length)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.turnover")} value={limitToTwoDecimals(totalAmount?.TotalOrder)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.discounts")} value={limitToTwoDecimals(totalAmount?.Discount)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(totalAmount?.TotalWithDiscount)} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md" fontSize="text-base"/>
            </Wrapper> 
         {sumsByStatus.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
            {sumsByStatus.map((item: any) => {
            return(
              <ZReportRow key={uuidv4()} title={item.status} value={limitToTwoDecimals(item.quantity)}/>
            )
          })}
            </Wrapper>
           {corrections &&  <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}}>
            <ZReportRow title={`${t("periodReport.corrections")} (${corrections?.length}x)`} value={limitToTwoDecimals(corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0))}/>
            <ZReportRow title={`${t("periodReport.discounts")} (${discounts?.length}x)`} value={limitToTwoDecimals(discounts.reduce((sum: any, item: any) => sum + item.DiscountAmount, 0))}/>
            {outOfTurnover.detailsOOT.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.detailsOOT.length}x)`} value={outOfTurnover && outOfTurnover.totalAmountOOT.TotalWithDiscount} color="bg-custom-bgOrange"/>) : (<></>)}
            </Wrapper>

              </div>
              <div className="w-1/2">
           {taxParentGroupDetails.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.parentTaxGroup")}
            </p>}
                   {/* parent group */}
              {isTaxParentGroup ? <Wrapper classes={`${taxParentGroupDetails.length > 0 ? " rounded-lg" : "mt-4"}`} onClick={() => {}}>
            
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-custom3">
              <ZReportRow title={t("periodReport.excludedVAT")} value={limitToTwoDecimals(getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="" fontSize="text-base"/>
              {taxExcludeVat.length > 0 ? taxExcludeVat.map((item: any) => {return(<ZReportRow key={uuidv4()}  title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />)}) : <></>}

              <ZReportRow title={t("periodReport.tax")} value={limitToTwoDecimals(getSum(tax))} color="bg-custom-bgPink"  classes=" mt-4" fontSize="text-base"/>
              {tax.length > 0 ? tax.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}

              <ZReportRow title={t("periodReport.turnover")} value={limitToTwoDecimals(getSum(turnover))} color="bg-custom-bgPink"  classes=" mt-4" fontSize="text-base"/>
              {turnover.length > 0 ? turnover.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}

              </Wrapper>
            </Wrapper> : <></>}


            {taxParentGroupDetails.length > 0 ? ( <div className="py-2 mb-3 ">
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.excludedVAT")}
            </p>

            {taxParentGroupDetails.length > 0 ? ( 
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {taxParentGroupDetails.length > 0 && taxParentGroupDetails[1].map((item: any) => {
                return (
                  <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
                );
              })}
              <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(taxExcludeVat && getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
            </Wrapper>) : (<></>)}

            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.tax")}
            </p>

            {taxParentGroupDetails.length > 0 ? (  
                    
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {taxParentGroupDetails.length > 0 && taxParentGroupDetails[2].map((item: any) => {
                return (
                  <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
                );
              })}
              <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(tax && getSum(tax))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
            </Wrapper>) : (<></>)}

            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.turnover")}
            </p>
            {taxParentGroupDetails.length > 0 ? (        
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-1 shadow-custom3">
              {taxParentGroupDetails.length > 0 && taxParentGroupDetails[3].map((item: any) => {
                return (
                  <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
                );
              })}
              <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(turnover && getSum(turnover))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
            </Wrapper>) : (<></>)}
              </div>) : (<></>)}

              </div>
              
            </div>
            </>
          
          
          ) : (<><div className="text-gray-500 pt-12 h-screen">{t("errorMessages.noDataToBeShownForThisPeriod")}</div></>)}
            <ScrollToTopButton />
    </Wrapper>
  );
};

export default PeriodReports;

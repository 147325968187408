import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import ReportsFilters from "../../components/reportsFilters/ReportsFilters";
import AnalyticComponent from "../../components/analyticComponent/AnalyticComponent";
import ChartsComponent from "../../components/chartsComponent/ChartsComponent";
import { useLocation } from "react-router-dom";
import SalesFilters from "../../components/salesFilters/SalesFilters";
import SalesReports from "../../components/salesReports/SalesReports";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";  
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import ZreportFilter from "../../components/zreportFilters/ZreportFilters";
import ZReports from "../../components/zReports/ZReports";
import PeriodReportFilter from "../../components/periodReportFilters/PeriodReportFilters";
import PeriodReports from "../../components/periodReports/PeriodReports";
import { getCookie } from "../../utilities/cookies";
import SoldProductsReportFilters from "../../components/soldProductsReportFilters/SoldProductsReportFilter";
import SoldProductsReports from "../../components/soldProductsReports/SoldProductsReports";
import PercentageReportFilters from "../../components/percentageReportFilters/PercentageReportFilters";
import PercentageReport from "../../components/percentageReport/PercentageReport";
import MainCategoryFilters from "../../components/mainCategoryFilters/MainCategoryFilters";
import MainCategoryReport from "../../components/mainCategoryReport/MainCategoryReport";
import useFilterHandlers from "../../hooks/useFilterHandlers";


const Reports: React.FC = () => {
  const {
    menuId,
    selectedDate,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    allFilters,
    allFiltersFromPercentageReport,
    allFiltersFromMainCategoryReport,
    handleMenuId,
    handleDate,
    handleFromDate,
    handleToDate,
    handleFromTime,
    handleToTime,
    handleSoldProductsFilters,
    handlePercentageReportFilters,
    handleMainCategoryReportFilters,
  } = useFilterHandlers();
  const location = useLocation();
  const { i18n } = useTranslation();
  const [liveData, setLiveData] = useState<any>();
  const [avgFoodSpendingData, setAvgFoodSpendingData] = useState([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [starsData, setStarsData] = useState([]);
  const [tablesAndSeatsData, setTablesAndSeatsData] = useState([]);
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = getCookie("token");
    
  useEffect(() => {
    if (
      location.pathname === "/reports/dashboard" ||
      location.pathname === "/reports/sales"
    ) {
      if (menuId) {
        const getData = async () => {
          const requestBody = {
            Language: i18n.language,
            IdMenu: menuId,
          };

          setIsLoadingLiveData(true);

          try {
            const apiResponse = await fetchData(API_ROUTES.getLiveData, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setAvgFoodSpendingData(
              apiResponse.data.AdditionalInformation.CategoryTypesPercentages,
            );
            setStarsData(apiResponse.data.AdditionalInformation.FeedbackStars);
            setLiveData(apiResponse.data);
            setTablesAndSeatsData(apiResponse.data.AdditionalInformation);
            setPaymentData(apiResponse.data.Payments);
          } catch (error) {
            console.error("Error fetching data:", error);
            setAvgFoodSpendingData([]);
            setStarsData([]);
            setLiveData(null);
            setTablesAndSeatsData([]);
            setPaymentData([]);
          } finally {
            setIsLoadingLiveData(false);
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname]);

  return (
    <div className="Reports w-full bg-custom-bgGray">
      {location.pathname === "/reports/dashboard" ? (
        <>
          <ReportsFilters sendMenuIdToParent={handleMenuId} />
          {liveData ? (
            <AnalyticComponent
              totalRevenue={liveData.CalculatedTotals.Forecast}
              turnoverPerGuest={liveData.CalculatedTotals.AvgPerPerson}
            />
          ) : (
            <AnalyticComponent totalRevenue={0} turnoverPerGuest={0}/>
          )}
          <ChartsComponent
            avgFoodSpendingData={avgFoodSpendingData}
            starsData={starsData}
            tablesAndSeatsData={tablesAndSeatsData}
          />
        </>
      ) : location.pathname === "/reports/sales" ? (
        <div className="w-full mx-auto overflow-x-hidden">
          {liveData ? (
            <SalesFilters
              sendMenuIdToParent={handleMenuId}
              timeSinc={liveData.AdditionalInformation.TimeSync}
              dateSinc={liveData.AdditionalInformation.DateSync}
            />
          ) : (
            <SalesFilters
              sendMenuIdToParent={handleMenuId}
              timeSinc="0"
              dateSinc="0"
            />
          )}
          {liveData ? (
            <SalesReports
              totalTurnoverValue={liveData.CalculatedTotals.TotalTurnover}
              pendingValue={liveData.CalculatedTotals.PendingTurnover}
              forecastValue={liveData.CalculatedTotals.Forecast}
              discountValue={liveData.CalculatedTotals.Discounts}
              occupiedValue={liveData.AdditionalInformation.TakenTablesCount}
              freeValue={liveData.AdditionalInformation.FreeTablesCount}
              avgBillValue={liveData.CalculatedTotals.AvgPerBill}
              avgPPValue={liveData.CalculatedTotals.AvgPerPerson}
              avgFoodSpendingData={avgFoodSpendingData}
              starsData={starsData}
              paymentData={paymentData}
            />
          ) : (
            <SalesReports
              totalTurnoverValue="0"
              pendingValue="0"
              forecastValue="0"
              discountValue="0"
              occupiedValue="0"
              freeValue="0"
              avgBillValue="0"
              avgPPValue="0"
              avgFoodSpendingData={avgFoodSpendingData}
              starsData={starsData}
              paymentData={paymentData}
            />
          )}
        </div>
      ) : location.pathname === "/reports/zreport" ? (
        <div className="w-full overflow-x-hidden">
          <ZreportFilter sendMenuIdToParent={handleMenuId} sendDateToParent={handleDate}/>
          {menuId && <ZReports menuId={menuId} date={selectedDate} location=""/>}
        </div>
      ) : location.pathname === "/reports/periodReport" ? (
        <div className="w-full overflow-x-hidden">
          <PeriodReportFilter sendMenuIdToParent={handleMenuId} sendFromDateToParent={handleFromDate} sendToDateToParent={handleToDate}/>
          {menuId && dateFrom && dateTo && <PeriodReports menuId={menuId} dateFrom={dateFrom} dateTo={dateTo}/>}
        </div>
      ) : location.pathname === "/reports/soldProducts" ? (
        <div className="w-full overflow-x-hidden">
        <SoldProductsReportFilters sendFiltersToParent={handleSoldProductsFilters}/>
        {<SoldProductsReports filters={allFilters}/>}
        
      </div>
      ) : location.pathname === "/reports/percentageReport" ? (
        <div className="w-full overflow-x-hidden">
        <PercentageReportFilters sendFiltersToParent={handlePercentageReportFilters}/>  
        <PercentageReport filters={allFiltersFromPercentageReport}/>
      </div>
    ) : location.pathname === "/reports/mainCategory" ? (
      <div className="w-full overflow-x-hidden">
        <MainCategoryFilters sendFiltersToParent={handleMainCategoryReportFilters} />
        <MainCategoryReport filters={allFiltersFromMainCategoryReport}/>
    </div>
  ) 
    : (<></>)
      }
    </div>
  );
};

export default withTranslation()(Reports);

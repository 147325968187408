import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import ZReportRow from "../zReportRow/ZReportRow";
import { useTranslation } from "react-i18next";
import { MenuType, PaymentType, ZProps } from "./ZReport.type";
import { v4 as uuidv4 } from 'uuid';
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";

const ZReports: React.FC<ZProps> = ({menuId, date}) => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [dailyTotals, setDailyTotals] = useState<any>();
  const [menuName, setMenuName] = useState("");
  const [reportInfo, setReportInfo] = useState<any>();
  const [taxExcludeVat, setTaxExcludeVat] = useState<any>([]);
  const [tax, setTax] = useState<any>([]);
  const [turnover, setTurnover] = useState<any>([]);
  const [discount, setDiscount] = useState<any>([]);
  const [corrections, setCorecctions] = useState<any>([]);
  const [data, setData] = useState<boolean>(false);
  const [ownUse, setOwnUse] = useState<any>([]);
  const [ordersTotals, setOrdersTotals] = useState<any>([]);
  const [outOfTurnover, setOutOfTurnover] = useState<any>([]);
  const [taxParentGroupDetails, setTaxParentGroupDetails] = useState<any>([]);
  const [orders, setOrders] = useState<any>([])
  const {t} = useTranslation();
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const [isMobile, setIsMobile] = useState<boolean>();
  
  useEffect(() => {
    if (
      location.pathname === "/reports/zreport"
    ) {
      if (menuId && date) {
        const getData = async () => {
          const requestBody = {
            Language: i18n.language,
            IdMenu: menuId,
            TargetDate: date
          };

          // setIsLoadingLiveData(true);

          try {
            const apiResponse = await fetchData(API_ROUTES.getZreport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setPaymentData(apiResponse.data.Payments);
            setDailyTotals(apiResponse.data.DailyTotal);
            setReportInfo(apiResponse.data.ReportInfo);
            setTaxExcludeVat(apiResponse.data.TaxExcludeVat);
            setTax(apiResponse.data.TaxVat);
            setTurnover(apiResponse.data.Turnover);
            setDiscount(apiResponse.data.Disocunts);
            setCorecctions(apiResponse.data.Corrections);
            setData(true);
            setOwnUse(apiResponse.data.OwnUse);
            setOrdersTotals(apiResponse.data.OrdersTotal);
            setOutOfTurnover(apiResponse.data.OutOfTurnover)
            setTaxParentGroupDetails(groupByTaxType(apiResponse.data.TaxParentGroupDetails));
            setOrders(apiResponse.data.Orders)

            // console.log(apiResponse.data.TaxParentGroupDetails)

          } catch (error) {
            // console.error("Error fetching data:", error);
            setPaymentData([]);
            setDailyTotals([]);
            setReportInfo("");
            setTaxExcludeVat([]);
            setTax([]);
            setTurnover([]);
            setDiscount([]);
            setCorecctions([]);
            setData(false);
            setOwnUse([]);
            setOrdersTotals([]);
            setOutOfTurnover([]);
            setTaxParentGroupDetails([]);
            setOrders([])
          } finally {
            setIsLoadingLiveData(false);
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname, date]);

  useEffect(() => {
    if(menuId){
      const userMenusString = localStorage.getItem("UserMenus");
      if (userMenusString) {
        const userMenus: MenuType[] = JSON.parse(userMenusString);
        const matchedMenu = userMenus.find(menu => menu.IdMenu.toString() === menuId);
        if (matchedMenu) {
          setMenuName(matchedMenu.MenuName);
        }
      }
    }
  }, [menuId]);

useEffect(() => {
  const userMenusString = localStorage.getItem("UserMenus");
  if (userMenusString) {
    const userMenus: MenuType[] = JSON.parse(userMenusString);
    if (userMenus.length > 0) {
      setMenuName(userMenus[0].MenuName);
    }
  }
}, []);


const getSum = (items: any) => {
  return items.reduce((total: any, item: any) => total + item.Ammount, 0);
}
const groupByTaxType = (details: any) => {
  return details.reduce((groups: any, item: any) => {
    if (!groups[item.TaxType]) {
      groups[item.TaxType] = [];
    }
    groups[item.TaxType].push(item);
    return groups;
  }, []);
};


let totalDiscountAmount;
let totalCorrcetionsAmount;
let totalPaymentsAmount;
{discount.length > 0 ? (totalDiscountAmount = discount.reduce((sum: any, item: any) => sum + item.Amount, 0)) : (totalDiscountAmount = 0)}
{corrections.length > 0 ? (totalCorrcetionsAmount = corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0)) : (totalCorrcetionsAmount = 0)}
{paymentData.length > 0 ? (totalPaymentsAmount = paymentData.reduce((sum: any, item: any) => sum + item.Ammount, 0)) : (totalPaymentsAmount = 0)}
const [sumsByStatus, setSumsByStatus] = useState<any>([]);

useEffect(() => {
  const sums: { [key: number]: { status: string; quantity: number } } = {};

  ownUse.forEach((order: any) => {
    const { IdStatus, Status, Quantity } = order;
    if (!sums[IdStatus]) {
      sums[IdStatus] = { status: Status, quantity: 0 };
    }
    sums[IdStatus].quantity += Quantity;
  });

  const mappedSums = Object.keys(sums).map((idStatus) => ({
    idStatus: parseInt(idStatus, 10), // Explicitly parse to number
    status: sums[parseInt(idStatus, 10)].status, // Explicitly parse to number
    quantity: sums[parseInt(idStatus, 10)].quantity, // Explicitly parse to number
  }));

  setSumsByStatus(mappedSums);
}, [ownUse]);

function limitToTwoDecimals(number: any) {
  if (number % 1 === 0) {
      return String(number);
  } else {
      return Number(number).toFixed(2);
  }
}

useEffect(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
  const isSmallScreen = window.innerWidth <= 768;

  setIsMobile(isMobileDevice && isSmallScreen);
}, []);

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto pt-4 pb-16">
      {orders.length > 0 && isMobile ? (
        // mobile
      <div className="w-full grid grid-cols-1 md:grid-cols-2 ">
                    <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.totals")}
            </p>
      {ordersTotals[0] ? ( <Wrapper onClick={() => {}} classes="h-fit w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
        <ZReportRow title={t("zreport.tables")} value={limitToTwoDecimals(ordersTotals[0]?.OrdersCount)}/>
        <ZReportRow title={t("zreport.person")} value={limitToTwoDecimals(ordersTotals[0]?.Persons)}/>
        <ZReportRow title={t("zreport.avgPB")} value={limitToTwoDecimals(ordersTotals[0]?.OrdersAvg)}/>
        <ZReportRow title={t("zreport.avgPP")} value={limitToTwoDecimals(ordersTotals[0]?.PersonsAvg)}/>
        <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(ordersTotals[0]?.SubTotal)} color="bg-custom-bgPink" classes="mt-2"/>
      </Wrapper>) : (<></>)}
     
      <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.parentTaxGroup")}
            </p>
     {isTaxParentGroup ?       <Wrapper classes={`${taxParentGroupDetails.length > 0 ? " rounded-lg mb-4" : ""}`} onClick={() => {}}>
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg">
              {taxExcludeVat.length > 0 ? taxExcludeVat.map((item: any) => {return(<ZReportRow key={uuidv4()}  title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="" fontSize="text-base"/>

              {tax.length > 0 ? tax.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(getSum(tax))} color="bg-custom-bgPink"  classes=" mt-4" fontSize="text-base"/>

              {turnover.length > 0 ? turnover.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(getSum(turnover))} color="bg-custom-bgPink"  classes=" mt-4" fontSize="text-base"/>

              </Wrapper>
            </Wrapper> : <></>}

       
        {taxParentGroupDetails.length > 0 ? (<div className=" ">
        <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.excludedVAT")}
            </p>  
          {taxParentGroupDetails.length > 0 ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
            {taxParentGroupDetails.length > 0 && taxParentGroupDetails[1].map((item: any) => {
              return (
                <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
              );
            })}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(taxExcludeVat && getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
          </Wrapper>) : (<></>)}

          <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.tax")}
            </p>  
          {taxParentGroupDetails.length > 0 ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
            {taxParentGroupDetails.length > 0 && taxParentGroupDetails[2].map((item: any) => {
              return (
                <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
              );
            })}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(tax && getSum(tax))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
          </Wrapper>) : (<></>)}

          <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.turnover")}
            </p>  
          {taxParentGroupDetails.length > 0 ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
            {taxParentGroupDetails.length > 0 && taxParentGroupDetails[3].map((item: any) => {
              return (
                <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
              );
            })}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(turnover && getSum(turnover))} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-base"/>
          </Wrapper>) : (<></>)}
            </div>) : (<></>)}
          
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.payment")}
            </p>  
            {paymentData.length > 0 && dailyTotals ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg h-fit">
            {paymentData.map(payment => (
              payment.Ammount !== 0 && (
                <ZReportRow key={uuidv4()} title={payment.PaymentType} value={limitToTwoDecimals(payment.Ammount.toString())} color={`${payment.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>
              )
            ))}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(totalPaymentsAmount)} color="bg-custom-bgPink" classes="mt-2" fontSize="text-base"/>
        </Wrapper>) : (<></>)}
        <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.dailyTotals")}
            </p>
        {dailyTotals[0] ? ( <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg" onClick={() => {}}>
          <ZReportRow title={t("zreport.turnover")} value={dailyTotals[0]?.Turnover} color="bg-custom-bgPink"/>
          <ZReportRow title={t("zreport.discount")} value={dailyTotals[0]?.Discount} color="bg-custom-bgPink"/>
          <ZReportRow title={t("zreport.total")} value={dailyTotals[0]?.Total} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md"/>
        </Wrapper>) : (<></>)}
            {sumsByStatus.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
        {sumsByStatus.length > 0 ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
        {sumsByStatus.map((item: any) => {
            return(
              <ZReportRow key={uuidv4()} title={item.status} value={limitToTwoDecimals(item.quantity)}/>
            )
          })}
        </Wrapper>) : (<></>)}
      <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>
          {outOfTurnover.Orders ? ( <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
          <ZReportRow title={`${t("zreport.corrections")} (${corrections.length}x)`} value={limitToTwoDecimals(totalCorrcetionsAmount)}/>
          <ZReportRow title={`${t("zreport.discount")} (${discount.length}x)`} value={limitToTwoDecimals(totalDiscountAmount)} />
          {outOfTurnover.Orders.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.Orders.length}x)`} value={outOfTurnover && outOfTurnover.DailyTotal[0].Total} color="bg-custom-bgOrange"/>) : (<></>)}
        </Wrapper>) : (<></>)}
       

        </div>) : orders.length > 0 && !isMobile ? (
        // desktop/////////////////////////////////////////////////////////////////////////////////////
        <>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-base md:text-xl text-custom-textBlackColor pb-2">
            {t("zreport.totals")}
            </p>
          {ordersTotals[0] ? ( <Wrapper onClick={() => {}} classes="h-fit w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
        <ZReportRow title={t("zreport.tables")} value={limitToTwoDecimals(ordersTotals[0]?.OrdersCount)}/>
        <ZReportRow title={t("zreport.person")} value={limitToTwoDecimals(ordersTotals[0]?.Persons)}/>
        <ZReportRow title={t("zreport.avgPB")} value={limitToTwoDecimals(ordersTotals[0]?.OrdersAvg)}/>
        <ZReportRow title={t("zreport.avgPP")} value={limitToTwoDecimals(ordersTotals[0]?.PersonsAvg)}/>
        <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(ordersTotals[0]?.SubTotal)} color="bg-custom-bgPink" classes="mt-2" fontSize="text-base"/>
      </Wrapper>) : (<></>)}
      <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2">
            {t("zreport.payment")}
            </p>
      {paymentData.length > 0 && dailyTotals ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg h-fit">
            {paymentData.map(payment => (
              payment.Ammount !== 0 && (
                <ZReportRow key={uuidv4()} title={payment.PaymentType} value={limitToTwoDecimals(payment.Ammount.toString())} color={`${payment.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>
              )
            ))}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(totalPaymentsAmount)} color="bg-custom-bgPink" classes="mt-2" fontSize="text-base"/>
        </Wrapper>) : (<></>)}
        <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2">
            {t("zreport.other")}
            </p>
        {outOfTurnover.Orders ? ( <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
          <ZReportRow title={`${t("zreport.corrections")} (${corrections.length}x)`} value={limitToTwoDecimals(totalCorrcetionsAmount)}/>
          <ZReportRow title={`${t("zreport.discount")} (${discount.length}x)`} value={limitToTwoDecimals(totalDiscountAmount)} />
          {outOfTurnover.Orders.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.Orders.length}x)`} value={outOfTurnover && outOfTurnover.DailyTotal[0].Total} color="bg-custom-bgOrange"/>) : (<></>)}
        </Wrapper>) : (<></>)}
      {sumsByStatus.length > 0 &&   <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2">
            {t("zreport.other")}
            </p>}
        {sumsByStatus.length > 0 ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
        {sumsByStatus.map((item: any) => {
            return(
              <ZReportRow key={uuidv4()} title={item.status} value={limitToTwoDecimals(item.quantity)}/>
            )
          })}
        </Wrapper>) : (<></>)}
        

          </div>
          <div className="">

        {taxParentGroupDetails.length > 0 ? (<div className="">
          <p className="text-base md:text-xl text-custom-textBlackColor pb-2">
            {t("zreport.excludedVAT")}
            </p>

          {taxParentGroupDetails.length > 0 ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
            {taxParentGroupDetails.length > 0 && taxParentGroupDetails[1].map((item: any) => {
              return (
                <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
              );
            })}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(taxExcludeVat && getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-base"/>
          </Wrapper>) : (<></>)}
          <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2 ">
            {t("zreport.tax")}
            </p>
          {taxParentGroupDetails.length > 0 ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
            {taxParentGroupDetails.length > 0 && taxParentGroupDetails[2].map((item: any) => {
              return (
                <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
              );
            })}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(tax && getSum(tax))} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-base"/>
          </Wrapper>) : (<></>)}
          <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2">
            {t("zreport.turnover")}
            </p>
          {taxParentGroupDetails.length > 0 ? (        
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
            {taxParentGroupDetails.length > 0 && taxParentGroupDetails[3].map((item: any) => {
              return (
                <ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />
              );
            })}
            <ZReportRow title={t("zreport.total")} value={limitToTwoDecimals(turnover && getSum(turnover))} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-base"/>
          </Wrapper>) : (<></>)}
            </div>) : (<></>)}
            <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2">
            {t("zreport.parentTaxGroup")}
            </p>
            {isTaxParentGroup ?       <Wrapper classes={`${taxParentGroupDetails.length > 0 ? " rounded-lg mb-4" : ""}`} onClick={() => {}}>
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg">
              {taxExcludeVat.length > 0 ? taxExcludeVat.map((item: any) => {return(<ZReportRow key={uuidv4()}  title={item.VatName} value={limitToTwoDecimals(item.Ammount)} />)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(getSum(taxExcludeVat))} color="bg-custom-bgPink"  classes="mb-4" fontSize="text-base"/>

              {tax.length > 0 ? tax.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(getSum(tax))} color="bg-custom-bgPink"  classes=" mb-4" fontSize="text-base"/>

              {turnover.length > 0 ? turnover.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={limitToTwoDecimals(item.Ammount)}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={limitToTwoDecimals(getSum(turnover))} color="bg-custom-bgPink"  classes=" " fontSize="text-base"/>

              </Wrapper>
            </Wrapper> : <></>}
          

            <p className="text-base md:text-xl text-custom-textBlackColor pt-4 pb-2">
            {t("zreport.dailyTotals")}
            </p>
        {dailyTotals[0] ? ( <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg" onClick={() => {}}>
          <ZReportRow title={t("zreport.turnover")} value={dailyTotals[0]?.Turnover} color="bg-custom-bgPink"/>
          <ZReportRow title={t("zreport.discount")} value={dailyTotals[0]?.Discount} color="bg-custom-bgPink"/>
          <ZReportRow title={t("zreport.total")} value={dailyTotals[0]?.Total} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md"/>
        </Wrapper>) : (<></>)}
      
      

       

        </div>

        </div>
        
        <div>
          
        </div>
        {/* ///////////////////////////////////////////////////////////////////////////////////// */}
        </>
      ) : (
          // error
      <><div className="text-gray-500 pt-12 h-screen">{t("errorMessages.noDataToBeShown")}</div></>) }

<ScrollToTopButton />

    </Wrapper>
  );
};

export default ZReports;

// hooks/useFetchSoldProductsFiltersData.ts
import { useEffect, useState } from "react";
import { API_ROUTES } from "../config";
import useAxios from "../hooks/useAxios";
import { useLocation } from "react-router-dom";

export const useFetchSoldProductsFiltersData = (menuId: string, token: any) => {
  const [timePeriod, setTimePeriod] = useState<any>([]);
  const [mainCategory, setMainCategory] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [taxParentType, setTaxParentType] = useState<any>([]);
  const [taxType, setTaxType] = useState<any>([]);
  const { fetchData } = useAxios();
  const location = useLocation();

  useEffect(() => {
    const fetchDataForMenu = async () => {
      const path = `${API_ROUTES.getFiltersData}?idClientMenu=${menuId}`;
      const apiResponse = await fetchData(path, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    if(apiResponse.data){
      setTimePeriod(apiResponse.data.ProductFilterType);
      setMainCategory(apiResponse.data.FullMenuData.MainCategories);
      setSubCategory(apiResponse.data.FullMenuData.SubCategories);
      setProducts(apiResponse.data.FullMenuData.Products);
      setTaxParentType(apiResponse.data.AllParentTaxPercents);
      setTaxType(apiResponse.data.AllTaxTypes);
    } else{
      setTimePeriod([]);
      setMainCategory([]);
      setSubCategory([]);
      setProducts([]);
      setTaxParentType([]);
      setTaxType([]);
    }
    };

    fetchDataForMenu();
  }, [menuId, location.pathname]);

  return { timePeriod, mainCategory, subCategory, products, taxParentType, taxType };
};

import { useState, useContext } from 'react';
import { PrivilegesContext } from '../context/privilegesContext';

const useFilterHandlers = () => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();
  const [timeFrom, setTimeFrom] = useState<any>();
  const [timeTo, setTimeTo] = useState<any>();
  const [allFilters, setAllFilters] = useState<any>();
  const [allFiltersFromPercentageReport, setAllFiltersFromPercentageReport] = useState<any>();
  const [allFiltersFromMainCategoryReport, setAllFiltersFromMainCategoryReport] = useState<any>();
  const { menuId, setMenuId } = useContext(PrivilegesContext);

  const handleMenuId = (selectedLocation: any) => {
    setMenuId(selectedLocation);
  };

  const handleDate = (selectedDate: any) => {
    setSelectedDate(selectedDate);
  };

  const handleFromDate = (selectedDate: any) => {
    setDateFrom(selectedDate);
  };

  const handleToDate = (selectedDate: any) => {
    setDateTo(selectedDate);
  };

  const handleSoldProductsFilters = (filters: any) => {
    setAllFilters(filters);
  };

  const handlePercentageReportFilters = (filters: any) => {
    setAllFiltersFromPercentageReport(filters);
  };

  const handleMainCategoryReportFilters = (filters: any) => {
    setAllFiltersFromMainCategoryReport(filters);
  };

  const handleFromTime = (selectedTime: any) => {
    setTimeFrom(selectedTime)
  };
  const handleToTime = (selectedTime: any) => {
    setTimeTo(selectedTime)
  };

  return {
    menuId,
    selectedDate,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    allFilters,
    allFiltersFromPercentageReport,
    allFiltersFromMainCategoryReport,
    handleMenuId,
    handleDate,
    handleFromDate,
    handleToDate,
    handleFromTime,
    handleToTime,
    handleSoldProductsFilters,
    handlePercentageReportFilters,
    handleMainCategoryReportFilters,
  };
};

export default useFilterHandlers;

import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { SoldProductsFiltersProps } from "./SoldProductsReportFilters.type";
import Select from "../common/select/Select";
import { useTranslation } from "react-i18next";
import { API_ROUTES } from "../../config";
import i18n from "../../i18n";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";
import DropdownComponent from "./DropdownComponent";
import useOptions from "../../hooks/useOptions";
import { useSoldProductsFilters } from "../../hooks/useSoldProductsFilters";
import { useFetchSoldProductsFiltersData } from "../../hooks/useFetchSoldProductsFiltersData";
import useDateFormatter from "../../hooks/useDateFormatter";
import useDateDisabling from '../../hooks/useDateDesabling'; 
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import CustomTimePicker from "../common/customTimePicker/CustomTimePicker";
import useTimeConverter from "../../hooks/useTimeConverter";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";


const SoldProductsReportFilters: React.FC<SoldProductsFiltersProps> = ({
  sendFiltersToParent
}) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const now = new Date();
  const { formatDateSP } = useDateFormatter();
  const {t}   = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([0]);
  const [productsOptions, setProductsOptions] = useState<any>([0])
  const menuId = selectedLocation;
  const token = localStorage.getItem("token");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth(), 1)));
  const [toDate, setToDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth() + 1, 0)));
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("23:00");
  const [selectedExportType, setSelectedExportType] = useState<any>("0")
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const {convertTimeToHour} = useTimeConverter();

  const { timePeriod, mainCategory, subCategory, products, taxParentType, taxType } = useFetchSoldProductsFiltersData(menuId, token);

  const { timePeriodOptions, mainCategoryOptions, taxParentTypeOptions, taxTypeOptions } = useOptions(
    timePeriod,
    mainCategory,
    taxParentType,
    taxType
  );
  const {
    selectedTimePeriod,
    selectedTax,
    selectedSubCategory,
    selectedMainCategory,
    selectedProducts,
    handleTimePeriodChange,
    handleTaxChange,
    handleSubCategoryChange,
    handleMainCategoryChange,
    handleProductsChange,
    handleParentTaxChange,
  } = useSoldProductsFilters(taxTypeOptions);
  //get allowed dates
  useEffect(() => {
    if (
       location.pathname === "/reports/soldProducts"
    ) {
      if (selectedLocation) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: selectedLocation,
            "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            if(apiResponse.data){
              const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
              setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
              setAllowedDatesToPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()))
            }
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [selectedLocation, location.pathname]);

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    selectedLocation, 
    token, 
    locationPathname: location.pathname 
  });

  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };
  
  const [allFilters, setAllFilters] = useState<any>({
    IdMenu: "",
    TargetDate: "",
    TargetDateTo: "",
    Language: "",
    FilterType: "1",
    HourFilterFrom: "0",
    HourFilterTo: "24",
    ReportType: "1",
    ExportType: "",
    User: "",
    MainCategoryIds: "",
    SubCategoryIds: "",
    ProductIds: "",
    TaxIds: ""
  });
  //set all filter
  useEffect(() => {
    setAllFilters({
      IdMenu: menuId?.toString(),
      TargetDate: fromDate,
      TargetDateTo: toDate,
      Language: i18n.resolvedLanguage,
      FilterType: selectedTimePeriod?.toString(),
      HourFilterFrom: convertTimeToHour(fromTime),
      HourFilterTo: convertTimeToHour(toTime),
      ReportType: "1",
      ExportType: +selectedExportType,
      User: "",
      MainCategoryIds: selectedMainCategory && selectedMainCategory.toString(),
      SubCategoryIds: selectedSubCategory?.toString(),
      ProductIds: selectedProducts?.toString(),
      TaxIds: selectedTax?.toString()
    })
  }, [selectedProducts, menuId, fromDate, toDate, i18n, fromTime, toTime, selectedExportType, selectedMainCategory, selectedSubCategory, selectedTax, selectedTimePeriod])
  //send filters to parent
  useEffect(() => {
    sendFiltersToParent(allFilters)
  },[allFilters])

  //update options
  useEffect(() => {
    const updateOptions = () => {
      // Update Sub Categories
      if (selectedMainCategory?.length > 0) {
        const filteredSubCategories = subCategory.filter((category: any) =>
          selectedMainCategory.includes(category.IdParent) || category.IdParent === 0
        );
        const mappedSubCategories = filteredSubCategories.map((category: any) => ({
          value: category?.Id,
          label: category?.Description
        }));
        setSubCategoryOptions(mappedSubCategories);
      } else {
        setSubCategoryOptions([{ value: subCategoryOptions?.[0]?.value, label: subCategoryOptions?.[0]?.label }]);
      }

      // Update Products
      if (selectedSubCategory?.length > 0 && products.length > 0) {
        const filteredProduct = products.filter(
          (product: any) => selectedSubCategory.includes(parseInt(product.IdParent)) || product.IdParent === 0
        );
        const mappedProducts = filteredProduct.map((product: any) => ({
          value: product?.Id,
          label: product?.Description
        }));
        setProductsOptions(mappedProducts);
      } else {
        setProductsOptions([{ value: products?.[0]?.Id, label: products?.[0]?.Description }]);
      }
    };

    updateOptions();
  }, [selectedMainCategory, selectedSubCategory, products, subCategory]);
    
  const { disabledDate, disabledToDate } = useDateDisabling({ allowedDatesFromPicker, allowedDatesToPicker });

  const toggleFilters = () => {
    setIsOpenFilters(!isOpenFilters)
  };

  return (
     <Wrapper onClick={() => {}} classes={`w-[95%] mx-auto md:pt-24 mt-1 flex flex-col items-center justify-center gap-4  ${isOpenFilters ? "pb-8 border-b": ""}`}>
      <div className="w-full flex flex-col md:flex-row items-center justify-center">


        <div className="w-full flex flex-col">

          <div className="w-full">
          <Heading
            align="left"
            children={t("soldProducts.title")}
            classes="pt-16 md:pt-0 mt-1 min-w-full md:text-4xl"
            color=""
            size="h1"
          />
          <Text
            align=""
            children={t("soldProducts.subTitle")}
            classes="font-thin"
            color="text-custom-textGraySec"
            size=""
          />
        </div>

        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
          />
          </div>
        <div className="w-full md:w-8/12 mt-4 flex gap-2">
          

        <div className="text-left w-1/2 ">
          <p className="mb-2">{t("soldProducts.fromToDate")}</p>
              {fromDate && toTime && 
              <span className="grid grid-cols-2 gap-2">
                <CustomDatePicker
                dateValue={fromDate}
                onDateChange={setFromDate}
                disabledDate={disabledDate}
                handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
                placeholder={t("datePicker.placeholder")}
                allowedDates={allowedDatesFromPicker}
                />
              <CustomDatePicker
              dateValue={toDate}
              onDateChange={setToDate}
              disabledDate={disabledToDate}
              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesToPicker)}}
              placeholder={t("datePicker.placeholder")}
              isToDatePicker={true}
              allowedDates={allowedDatesToPicker}
              />
              </span>
      }
        </div>

        <div className="text-left w-1/2">
          <p className="mb-2">{t("soldProducts.fromToTime")}</p>
          <span className="grid grid-cols-2 gap-2">
              <CustomTimePicker
                selectedTime={fromTime}
                onTimeChange={setFromTime}
                />
              <CustomTimePicker
                selectedTime={toTime}
                onTimeChange={setToTime}
                />
          </span>
        </div>
        </div>
        </div>

        <div onClick={toggleFilters} className="flex justify-between w-full border rounded-lg px-4 py-2.5 bg-white">{t("soldProducts.filters")} {isOpenFilters ? <ArrowUpIcon/> : <ArrowDownIcon />} </div>
        {isOpenFilters ? 
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">

         <DropdownComponent
        label={t("soldProducts.timePeriod")}
        options={timePeriodOptions}
        onChange={handleTimePeriodChange}
        defaultValue={1}
      />

        <DropdownComponent
        label={t("soldProducts.mainCategory")}
        options={mainCategoryOptions}
        onChange={handleMainCategoryChange}
        isMultiSelect
      />
        <DropdownComponent
        label={t("soldProducts.subCategory")}
        options={subCategoryOptions}
        onChange={handleSubCategoryChange}
        isMultiSelect
      />

        <DropdownComponent
        label={t("soldProducts.products")}
        options={productsOptions}
        onChange={handleProductsChange}
        isMultiSelect
      />
        {isTaxParentGroup ? <DropdownComponent
        label={t("soldProducts.taxParentType")}
        options={taxParentTypeOptions}
        onChange={handleParentTaxChange}
        isMultiSelect
      /> : <></>}
        

        <DropdownComponent
        label={t("soldProducts.taxType")}
        options={taxTypeOptions}
        onChange={handleTaxChange}
        isMultiSelect
      />
        </div> 
        :
        <></>}
       
    </Wrapper >
  );
};

export default SoldProductsReportFilters;

import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { PeriodReportFilterProps } from "./PeriodReportFilters.type";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import useDateFormatter from "../../hooks/useDateFormatter";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import CustomTimePicker from "../common/customTimePicker/CustomTimePicker";

const PeriodReportFilter: React.FC<PeriodReportFilterProps> = ({
  sendMenuIdToParent,
  sendFromDateToParent,
  sendToDateToParent
}) => {
  const { t } = useTranslation();
  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const { formatDateSP } = useDateFormatter();
  const currentDate = new Date();
  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)));
  const [toDate, setToDate] = useState<any>(formatDateSP(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)));  
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();

  
  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };
  useEffect(() => {
    if (
       location.pathname === "/reports/periodReport"
    ) {
      if (selectedLocation) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: selectedLocation,
            "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
            setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
            setAllowedDatesToPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [selectedLocation, location.pathname]);

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    selectedLocation, 
    token, 
    locationPathname: location.pathname 
  });


  useEffect(() => {
    sendMenuIdToParent(selectedLocation);
    sendFromDateToParent(fromDate);
    sendToDateToParent(toDate);
  }, [selectedLocation, fromDate, toDate]);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const { disabledDate, disabledToDate } = useDateDisabling({ allowedDatesFromPicker, allowedDatesToPicker });

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-24 mt-1 flex flex-col md:flex-row items-center justify-center">
      <div className="w-full flex flex-col">

        <div className="w-full">
            <Heading
              align="left"
              children={t("periodReport.title")}
              classes="pt-16 md:pt-0 min-w-full md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("periodReport.subTitle")}
              classes="font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>

        <Select
          classes="md:hidden md:w-1/3 flex justify-center rounded-lg border border-custom-bgBlue bg-white relative mt-4 mb-2"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
          />
          </div>
        <div className="w-full md:w-8/12 mt-4 flex gap-2 ">

        
        <div className="text-left w-1/2">
          <p className="mb-2">{t("periodReport.from")}</p>
          <span className="grid grid-cols-1 gap-2">
            {fromDate && 
            <>
                <CustomDatePicker
                dateValue={fromDate}
                onDateChange={setFromDate}
                disabledDate={disabledDate}
                handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
                placeholder={t("datePicker.placeholder")}
                allowedDates={allowedDatesFromPicker}
                />
                {/* <CustomTimePicker
                selectedTime={fromTime}
                onTimeChange={setFromTime}
                /> */}
                </>
              }
              </span>
        </div>
        <div className="text-left w-1/2">
          <p className="mb-2">{t("periodReport.to")}</p>
          <span className="grid grid-cols-1 gap-2">

            {toDate && 
            <>
                          <CustomDatePicker
                          dateValue={toDate}
                          onDateChange={setToDate}
                          disabledDate={disabledToDate}
                          handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesToPicker)}}
                          placeholder={t("datePicker.placeholder")}
                          isToDatePicker={true}
                          allowedDates={allowedDatesToPicker}
                          />
                          {/* <CustomTimePicker 
                          isToTime={true}
                          selectedTime={toTime}
                          onTimeChange={setToTime}
                          /> */}
                          </>
} 
                          </span>
        </div>
        </div>
    </Wrapper>
  );
};

export default PeriodReportFilter;

import Dropdown from "../common/dropdown/Dropdown";

interface DropdownComponentProps {
  label: any;
  options: { value: any; label: any }[];
  onChange: (value: any) => void;
  isMultiSelect?: boolean;
  defaultValue?: number;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({ label, options, onChange, isMultiSelect = false, defaultValue }) => {
  return (
    <div className="w-full flex justify-between items-center">
      <p className="w-full md:w-1/3">{label}</p>
      <Dropdown
        isMultiSelect={isMultiSelect}
        onChange={onChange}
        options={options}
        title={options[defaultValue ? defaultValue : 0]?.label}
      />

    </div>
  );
};


export default DropdownComponent;